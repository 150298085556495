import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import SampleForm from '../components/Form'
import 'react-lazy-load-image-component/src/effects/blur.css'
import swish from '../img/swish.png'
import swishw from '../img/swish-white.png'
import coin from '../img/coinicon.svg'
import dollar from '../img/dollar.svg'
import pension from '../img/pension.svg'
import laura from '../img/laura-atf-signature.jpg'
import moneydialogue from '../img/money-dialogue.svg'

class IndexPage extends React.Component {
  // componentDidMount() {
  //   // 1. Create <script>Tag</script>
  //   const script = document.createElement('script')
  //   // 2. <script async>
  //   script.async = true
  //   // 3. <script src="src">
  //   script.src = 'https://assets.calendly.com/assets/external/widget.js'
  //   // 4. <script id="id">
  //   script.id = 'calendly'
  //   // 5. Insert script by getElementID
  //   document.getElementById('calendly').appendChild(script)
  // }

  render() {
    const siteTitle = 'Home'
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <div>
          {/* <WebinarModal /> */}
          <div className="atf">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-md-10 col-lg-8 mt-5 mb-3">
                  <h1 className="atf-h text-blue text-center mb-4">
                    <strong>Making Your Money Have More Meaning</strong>
                  </h1>
                </div>
                <div className="col-lg-7 pb-5">
                  <h2 className="text-left mb-4">Who I Serve</h2>
                  <div className="mb-4">
                    <h3 className="atf-p text-blue text-left mb-1">
                      <strong>
                        Retirees with $500,000 or more in retirement savings.
                      </strong>
                    </h3>
                    <p
                      className="service-p text-left my-2"
                      style={{ lineHeight: '1.5' }}
                    >
                      I help individuals and couples transition from earning a
                      paycheck to taking a retirement paycheck from their
                      savings while balancing their retirement spending and
                      legacy goals.
                    </p>
                    <a
                      className="bttn bttn-small m-0"
                      href="/retiree-questionnaire"
                    >
                      Get Started
                    </a>
                  </div>

                  <div className="mb-4">
                    <h3 className="atf-p text-blue text-left mb-1">
                      <strong>
                        High earners not rich yet (aka HENRY) with $1000 or more
                        extra cash each month
                      </strong>
                    </h3>
                    <p
                      className="service-p text-left my-2"
                      style={{ lineHeight: '1.5' }}
                    >
                      I help high income earners prioritize their financial
                      goals and determine how to spend/save/payoff debt while
                      managing financial risk with their extra monthly income.
                    </p>
                    <a
                      className="bttn bttn-small m-0"
                      href="/henry-questionnaire"
                    >
                      Get Started
                    </a>
                  </div>

                  <div className="mb-4">
                    <h3 className="atf-p text-blue text-left mb-1">
                      <strong>
                        Small business owners wanting to optimize their employer
                        sponsored retirement plan
                      </strong>
                    </h3>
                    <p
                      className="service-p text-left my-2"
                      style={{ lineHeight: '1.5' }}
                    >
                      I help small business owners select and implement an
                      employer sponsored retirement plan that supports the
                      business owners and their employees in working toward
                      their retirement goals.
                    </p>
                    <a
                      className="bttn bttn-small m-0"
                      href="/small-business-owners-questionnaire"
                    >
                      Get Started
                    </a>
                  </div>
                </div>
                <div className="col-lg-5 align-self-end">
                  <img alt="Photo of Laura" src={laura} />
                </div>
              </div>
            </div>
          </div>
          <div id="about" className="about">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="bg text-center">
                    <img alt="coin" src={coin} style={{ maxWidth: '100%' }} />
                  </div>
                  <h1 className="section-h text-center">
                    <strong>
                      We care as much about the journey as the destination.
                    </strong>
                  </h1>
                  <div className="btn-style">
                    <Link className="bttn" to="/who-we-are">
                      Who We Are
                    </Link>
                  </div>
                </div>
                <div className="col-md-6">
                  <p className="about-p">
                    <strong>
                      Laura started Journey Financial Services, Inc., a local,
                      Colorado Springs, CO, boutique investment services firm,
                      to focus on guiding individuals towards clarifying their
                      retirement goals and strategically working towards them,
                      while placing proper priority on other financial
                      obligations.
                    </strong>
                    <br />
                  </p>
                  <p className="about-p">
                    <strong>
                      As a true collaborator with her clients, she is supported
                      by LPL Financial as the registered investment adviser and
                      broker dealer. This professional support allows Laura to
                      offer carefully vetted investment products and services
                      that serve to customize the unique needs and situations of
                      her clients.
                    </strong>
                    <br />
                  </p>

                  <div className="swish text-center">
                    <img alt="swish" src={swish} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="retirement" className="mission-section">
            <div className="container">
              <div className="bg text-center">
                {/*<img alt="retirementplan" src={retirementplan} />*/}
              </div>
              <div className="col-md-12">
                <h1 className="section-h text-center text-white">
                  <strong>Smooth sailing in your retirement journey</strong>
                </h1>
                <p className="atf-p text-center text-white">
                  <strong>
                    I have been in the financial services industry for 30+ years
                    and have broad experience in various areas of responsibility
                    in large banking, mortgage lending and retail financial
                    advice institutions.&nbsp;
                  </strong>

                  <strong>
                    For Individuals/couples within 5 years of retirement, or
                    retired within the last 5 years. Let's talk abut your
                    financial future.
                  </strong>
                </p>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-3">
                  <p className="atf-p text-white bg-1">Transparent</p>
                </div>
                <div className="col-md-3">
                  <p className="atf-p text-white bg-1">Responsive</p>
                </div>
                <div className="col-md-3">
                  <p className="atf-p text-white bg-1">Understanding</p>
                </div>
                <div className="col-md-3">
                  <p className="atf-p text-white bg-1">Comprehensive</p>
                </div>
              </div>
              <Link className="bttn float-center" to="/our-process">
                What We Do
              </Link>
            </div>
          </div>
          <div id="services" className="services">
            <div className="container">
              <div className="bg text-center">
                <img alt="dollar" src={dollar} />
              </div>
              <h1 className="section-h text-center">
                <strong>
                  Be your professional financial navigation partner
                </strong>
                <br />
              </h1>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-4 service-box">
                  <h1 className="service-h">Investment Management</h1>
                  <p className="service-p">
                    Helping clients select the proper securities to pursue their
                    financial goals.&nbsp;
                  </p>
                  <div className="swish text-center">
                    <img alt="swish" src={swish} />
                  </div>
                </div>
                <div className="col-md-4 service-box">
                  <h1 className="service-h">
                    Financial <br />
                    Planning
                  </h1>
                  <p className="service-p">
                    Helping clients make informed complex financial decisions.
                  </p>
                  <div className="swish text-center">
                    <img alt="swish" src={swish} />
                  </div>
                </div>
                <div className="col-md-4 service-box">
                  <h1 className="service-h">
                    Life <br />
                    Insurance
                  </h1>
                  <p className="service-p">
                    Helping clients with their financial security in case of
                    emergencies.
                  </p>
                  <div className="swish text-center">
                    <img alt="swish" src={swish} />
                  </div>
                </div>
                <div className="col-md-12 mt-5">
                  <Link className="bttn" to="/our-services" target="">
                    Our Services
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div id="schedule" className="schedule">
            <div className="container">
              <div className="row">
                <div className="col-md-6 sched">
                  <div className="bg text-center">
                    <img alt="pension" src={pension} />
                  </div>
                  <h1 className="section-h text-center text-white">
                    <strong>
                      Schedule a 15 minute phone call to gain clarity on next
                      steps
                    </strong>
                  </h1>
                  <p className="atf-p text-white text-center">
                    Before committing your time or ours, this 15 minute phone
                    call will give us both a chance to make sure your situation
                    matches our experience. If we aren’t a good fit for each
                    other we will gladly introduce you to a firm who is better
                    suited to your needs.
                  </p>
                  <div className="swish text-center">
                    <img alt="swishw" src={swishw} />
                  </div>
                </div>
                <div className="col-md-6 sched align-self-center text-center text-white">
                  <Link
                    to="/appointment"
                    className="btn text-white py-3 bttn"
                    style={{ fontSize: '25px' }}
                    target="_blank"
                  >
                    <i className="fa fa-leaf" />
                    &nbsp; Schedule an Appointment
                  </Link>
                  {/* <div
                    className="calendly-inline-widget"
                    data-url="https://calendly.com/calendtest"
                  /> */}
                </div>
              </div>
            </div>
          </div>
          {/*
          <Link to="/blog">Go to Blog</Link>
          <br />
          <br />
          <hr style={{ marginTop: `60px` }} />
          <h1>Sample Swiper</h1>
          <br />
          <MySwiper slidesperview={3}>
            <div>
              <LazyLoadImage
                effect="blur"
                className="img-fluid"
                src="https://source.unsplash.com/collection/145698/400x400"
                alt=""
              />
            </div>
            <div>
              <LazyLoadImage
                effect="blur"
                className="img-fluid"
                src="https://source.unsplash.com/collection/2203755/400x400"
                alt=""
              />
            </div>
            <div>
              <LazyLoadImage
                effect="blur"
                className="img-fluid"
                src="https://source.unsplash.com/collection/362271/400x400"
                alt=""
              />
            </div>
            <div>
              <LazyLoadImage
                effect="blur"
                className="img-fluid"
                src="https://source.unsplash.com/collection/3657445/400x400"
                alt=""
              />
            </div>
            <div>
              <LazyLoadImage
                effect="blur"
                className="img-fluid"
                src="https://source.unsplash.com/collection/385548/400x400"
                alt=""
              />
            </div>
            <div>
              <LazyLoadImage
                effect="blur"
                className="img-fluid"
                src="https://source.unsplash.com/collection/325867/400x400"
                alt=""
              />
            </div>
            <div>
              <LazyLoadImage
                effect="blur"
                className="img-fluid"
                src="https://source.unsplash.com/collection/1118905/400x400"
                alt=""
              />
            </div>
            <div>
              <LazyLoadImage
                effect="blur"
                className="img-fluid"
                src="https://source.unsplash.com/collection/2270935/400x400"
                alt=""
              />
            </div>
            <div>
              <LazyLoadImage
                effect="blur"
                className="img-fluid"
                src="https://source.unsplash.com/collection/1252081/400x400"
                alt=""
              />
            </div>
            <div>
              <LazyLoadImage
                effect="blur"
                className="img-fluid"
                src="https://source.unsplash.com/collection/2411320/400x400"
                alt=""
              />
            </div>
          </MySwiper>
        */}
          <div id="newsletter" className="cont">
            <div className="container">
              <div className="col-md-12">
                <div className="bg text-center">
                  <img alt="moneydialogue" src={moneydialogue} />
                </div>
                <h1 className="section-h text-center text-white">
                  <strong>
                    Get advice from Journey Financial Services&nbsp;
                  </strong>
                </h1>
                <p className="service-p text-white">
                  <em>
                    You will automatically be subscribed to our quarterly
                    newsletter&nbsp;to receive e-mails&nbsp;filled with
                    articles, advice and financial guidance
                  </em>
                </p>
              </div>
            </div>
            <div className="container">
              <SampleForm
                form_name="Newsletter Form"
                form_id="5df865b7676f4567eacd2532"
                form_classname="form-newsletter"
                className="row justify-content-center"
                method="post"
              >
                <div className="form-row">
                  <div className="mb-3">
                    <div className="form-group">
                      <input
                        className="form-control c-form"
                        type="text"
                        name="Name"
                        id="full_name"
                      />
                      <label htmlFor="full_name">Enter Your Name</label>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="form-group">
                      <input
                        className="form-control c-form"
                        type="email"
                        name="Email Address"
                        id="email_address"
                      />
                      <label htmlFor="email_address">Email</label>
                    </div>
                    <small className="form-text text-danger">
                      Please enter a correct email address.
                    </small>
                  </div>
                  <div className="mb-3">
                    <div className="form-group">
                      <input
                        className="form-control c-form"
                        type="text"
                        name="MailingAddress"
                        id="mailing_address"
                      />
                      <label htmlFor="mailing_address">Mailing Address</label>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="form-group">
                      <input
                        className="form-control c-form"
                        type="text"
                        name="Phone"
                        id="phone"
                      />
                      <label htmlFor="phone">Phone</label>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="form-group">
                      <button
                        className="btn btn-primary bttn float-left c-button"
                        type="submit"
                      >
                        Subscribe
                      </button>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="form-group">
                      <div className="webriq-recaptcha" />
                    </div>
                  </div>
                </div>
              </SampleForm>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
